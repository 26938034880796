import React, { useState, useEffect } from 'react';
import { KEY_BACKQUOTE_NAME, KEY_DOWN_EVENT } from '../constant';
import '../../scss/app.css';

export default function MyApp({ Component, pageProps }) {
    const [designSystemIsOpen, setDesignSystemIsOpen] = useState(false);

    const handleKeyDown = (e) => {
        if(e.ctrlKey && e.shiftKey && e.code === KEY_BACKQUOTE_NAME) {
            setDesignSystemIsOpen(prev => !prev);
        }
    };
 
    useEffect(() => {
        document.addEventListener(KEY_DOWN_EVENT, handleKeyDown);
        return () => document.removeEventListener(KEY_DOWN_EVENT, handleKeyDown);
    },[]);

    return (
        designSystemIsOpen ?
            <Component {...pageProps} />
            :
            null
    );
};
